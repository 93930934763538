import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Button,
  Stack,
  Collapse,
  useDisclosure,
  Image,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom'; // Make sure to import this for routing
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';


const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  const NavLink = ({ children, to }) => (
    <Link
      as={RouterLink}
      to={to}
      px={2}
      py={1}
      rounded={'md'}
      variant="link" 
      style={{ fontWeight: 'bold', fontFamily: 'Avenir Next', color: 'white' }}
    >
      {children}
    </Link>
  );
  const InstagramButton = (
    <Button
      as="a"
      href="https://www.instagram.com/lumo_tutoring/"
      variant="link"
      size="lg"
      isExternal
      aria-label="Instagram"
      color="white"
    >
      <FaInstagram size="1.5em" />
    </Button>
  );

  const WhatsappButton = (
    <Button 
    as="a"
    href="https://wa.me/message/7BIHKFAHKXSMP1"
    variant = "link"
    size = 'lg'
    isExternal
    aria-label="WhatsApp"
    color="white">
      <FaWhatsapp size="1.5em" />
    </Button>
  )
  

  return (
<Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1.5rem" bgGradient="linear(to-l,#204f99,#204f99)" boxShadow ="dark-lg" color="white">
  
  {/* Left Section with Logo and Links */}
  <Flex align="center" flexGrow={1}>
    {/* Logo clickable as a button */}
    <Button as={RouterLink} to="/" variant="ghost" _hover={{ bg: 'transparent' }}>
      <Image
        src={`${process.env.PUBLIC_URL}/logo.png`} // Make sure this is your logo path
        alt="LUMO"
        height="35px"
      />
    </Button>

    {/* Navigation Links */}
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing="24px"
      align="center"
      marginLeft={4}
      display={{ base: 'none', md: 'flex' }} // This line hides the links on base and shows them on md and larger screens
    >
      <NavLink to="/about">About Lumo</NavLink>
      <NavLink to="/OurServices">Our Services</NavLink>
      <NavLink to="/tutors">Meet Our Tutors</NavLink>
      <NavLink to="/join">Join Us</NavLink>
    </Stack>
  </Flex>

  {/* Right Section with Instagram Icon */}
  <Flex align="center">
    {/* Instagram Button with the white icon */}
    <Button as="a" href="https://www.instagram.com/lumo_tutoring/" variant="link" display={{ base: 'none', md: 'flex' }} size="lg" isExternal aria-label="Instagram">
      <FaInstagram size="1.5em" color="white" />
    </Button>
    <Button as="a" href="https://wa.me/message/7BIHKFAHKXSMP1" variant="link" display={{ base: 'none', md: 'flex' }} size="lg" isExternal aria-label="WhatsApp">
      <FaWhatsapp size="1.5em" color="white" />
    </Button>

    {/* Hamburger Menu IconButton with increased size */}
    <IconButton
      display={{ base: 'block', md: 'none' }}
      onClick={onToggle}
      icon={<HamburgerIcon w={8} h={8} />}
      variant="link"
      aria-label="Toggle Navigation"
      size="lg"
      fontSize="24px" // Or set a custom font size to increase the icon size
      marginLeft={2}
      color='white'
    />
  </Flex>
    <Box
      display={{ base: isOpen ? 'block' : 'none', md: 'none' }}
      flexBasis={{ base: '100%', md: 'auto' }}
    >
      <Stack
        direction="column"
        spacing="24px"
        align="center"
      >
        <NavLink to="/about">About Lumo</NavLink>
        <NavLink to="/OurServices">Our Services</NavLink>
        <NavLink to="/tutors">Meet Our Tutors</NavLink>
        <NavLink to="/join">Join Us</NavLink>
        <Box>{InstagramButton}{WhatsappButton}</Box>

      </Stack>
    </Box>
</Flex>
  );
};

export default Navbar;
