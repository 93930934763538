import React from 'react';
import {
  Box,
  Heading,
  Text,
  Container,
  SimpleGrid,
  VStack,
  Image,
  Divider,
  useColorModeValue,
  Stack
} from '@chakra-ui/react';
import Navbar from './Navbar';
import founders from './Founders';
import ProfileCard from './ProfileCard';
import Footer from './Footer';

const benefits = {
  '': [
    { title: 'Personalised Care 🪡', description: "We offer quick turnaround times for all enquiries and constantly monitor our tutors to ensure that we're providing your family with the level of care it deserves.", emphasis: false },
    { title: 'Trained Tutors 👨‍💼', description: "We ensure that all tutors go through a qualification course that equips them with the skillset necessary to give your children the support they deserve.", emphasis: false },
    { title: 'Unmatched Experience 🧠', description: "Our founders have decades of collective private education experience, and many of our tutors are qualified teachers. Our approach centres around a holistic approach to each student.", emphasis: false }
  ],
};

const BenefitCard = ({ title, description, emphasis }) => {
return (
  <Stack
    bg={useColorModeValue('white', 'gray.700')}
    boxShadow={'lg'}
    p={6}
    rounded={'3xl'}
    align={'start'}
    spacing={4}
    border={emphasis ? '2px' : '0px'}
    borderColor={emphasis ? 'teal.400' : 'gray.100'}
    transform={emphasis ? 'scale(1.05)' : 'none'}
    _hover={{
      boxShadow: '2xl',
      transform: 'scale(1.07)',
      borderColor: 'teal.500',
    }}
    transition={'transform 0.6s, box-shadow 0.2s, border-color 0.2s'}
  >
    <Heading size={'md' }fontWeight="semibold">{title}</Heading>
    <Text color={useColorModeValue('gray.600', 'gray.200')}>
      {description}
    </Text>
  </Stack>
);
};

const About = () => {
  return (
    <>
    <Navbar/>
    <Container maxW="container.lg" py={12}>
      <Heading as="h1" size="xl" textAlign="center" py={3} mb={6}>About Lumo</Heading>
      <Text textAlign="left" mb={6} fontFamily="'Avenir Next', sans-serif">
      Inititally founded in Milan by a collective of high-performing IB graduates and seasoned
educational consultants, Lumo is a tutoring agency committed to the mission of
delivering premier educational support to secondary school students. Over the last
year, Lumo’s clientele has grown beyond Italy, and presently includes families based
in countries such as Saudi Arabia, the UK, and Switzerland.
      </Text>
      <Text textAlign="left" mb={6} fontFamily="'Avenir Next', sans-serif">
      Our ethos centers around providing students with comprehensive, personalised
tutoring support in an international school environment marred by high staff turnover,
variable teaching quality and a lack of student-centred support. With an average IB
score of 42, Lumo’s founders are acutely aware of the IB’s rigor and demands, as well
as the skillset necessary for achieving stellar results and gaining admission to globally-
renowned universities.
      </Text>
      <Text textAlign="left" mb={6} fontFamily="'Avenir Next', sans-serif">
      Lumo’s strength is undoubtedly our team of over 100 tutors hailing from universities
which include Oxford, Cambridge, UCL, Bocconi and Imperial College. We strongly
believe in investing in tutor development opportunities and can commit to providing
our clients with an unmatched level of support and service.
      </Text>
      
      <Heading as="h2" size="lg" textAlign="center" py={3} pt={75} mb={6}>What makes us special?</Heading>
      
      {Object.entries(benefits).map(([category, services], index) => (
        <Box key={index} width="full">
          <Heading as="h1" size="lg" textAlign="center" mb={6}>
            {category}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {services.map((service, serviceIndex) => (
              <BenefitCard key={serviceIndex} {...service} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
      

      <VStack spacing={5} align="stretch">
        <linebr/>
        <linebr/>

        <Heading as="h2" size={'lg'} my={10} mb={4} textAlign="center">
          Meet the Team
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {founders.map((founder) => (
          <ProfileCard key={founder.name} {...founder} />
        ))}
      </SimpleGrid>
      </VStack>
    </Container>
    <Footer/>
    </>
  );
};

export default About;