// Tutor class definition (if needed)
export class Tutor {
    constructor(name, bio, level, subjects, extendedBio, education, experience, image) {
      this.name = name;
      this.bio = bio;
      this.level = level;
      this.subjects = subjects;
      this.extendedBio = extendedBio; 
      this.education = education;
      this.experience = experience;
      this.image = image
    }
  }
  
  const tutorProfiles = [
    new Tutor(
        "Tom",
        "Lumo Co-Founder and tutor specialising in supporting IB students",
        "Subject Specialist",
        ["Mathematics", "Economics"],
        "Tom is an international student originally from New Zealand and France. He moved to Milan to study at Bocconi University where he is currently majoring in Finance. He is extremely passionate about the financial industry and eager to learn and gain as many experiences as he can in the field. His other hobbies include playing football, skiing, watching rugby, and playing poker with friends. Apart from being both a native English and French speaker, he has an intermediate German level and is trying his best to become proficient in Italian.",
        "Bocconi University BSc. Economics and Finance (2024) | IB Score: 41/45 | IGCSE: 5 A*, 2 A",
        "Tom has 3 years of experience as an IB tutor and 4 years as an IGCSE tutor, including assistance with IA and EE development. He spent time teaching English and Maths to students in Thailand in February 2020, and has been tutoring students in his classes and grades below him since the age of 14. Tom takes pride in his experience writing articles and essays, which translate into his natural ability to help students improve their essay writing skills for IB exams",
        "/Images/Tom.png"
        ),

      new Tutor(
        "Noel",
        "A hobbyist pilot with 9+ years of tutoring experience",
        "Expert",
        ["Mathematics", "Economics", "Business", "Computer Science"],
        "Following his graduation from LBS, Noel has worked at Google, HSBC, BlackRock, and the European Central Bank, among other places. He lives by his grandfather's motto of 'learn, learn, learn and dream big'. An avid reader, he particularly enjoys biographies, but is also a keen athlete. He recently completed a helicopter pilot license which he holds in addition to a private pilot license, and enjoys taking his friends on sightseeing flights on weekends.",
        "University College London BSc. Economics | London Business School Master in Financial Analysis",
        "Noel has been tutoring since 2016 and has helped over 50 students in that time, helping them achieve their goals of of excelling in exams, gaining admission to their dream school, or finding a passion for a subject they didn't know they could enjoy. He is known to give constructive, thorough feedback and to inspire confidence in his students.",
        "/Images/Noel.jpeg"
        ),


      new Tutor(
        "Alice",
        "A French international student with extensive IB tutoring experience",
        "Subject Specialist",
        ["French", "Economics", "Mathematics"],
        "Although she was originally born in France, Alice spent a number of years living in the US during secondary school. She attended New York's United Nations International School, where she completed the IB Diploma Programme before returning to Europe to study Economic and Social Sciences at Bocconi University. She is fluent in English and French, and speaks conversational Spanish and Italian. She is passionate about the environment and social issues, and has engaged with them through active participations in Bocconi's Building Sustainable Innovation association and TedxBocconiU. In her free time, Alice enjoys team sports, running and cooking.",
        "Bocconi University BSc. Economic and Social Sciences (2024) | IB Score: 43/45",
        "Alice’s tutoring experience consists of two years of French and Mathematics support for students in the grades below. She has extensive knowledge of how to explain concepts to others and how to write essays following the IB’s structure and expectations in English as well as French.",
        "/Images/Alice.jpg"
        ),

      new Tutor(
        "Julian",
        "Bocconi student with a remarkable academic record, including a 1580 SAT",
        "Subject Specialist",
        ["SAT", "Economics", "Mathematics", "English"],
        "Julian grew up in Kuala Lumpur in a Malaysian-Singaporean family, and now studies at Bocconi where he received a merit-based 100% tuition waiver. He is a native english speaker, as wel as speaking Malay and Mandarin Chinese. He's an avid pianist, and has achieved an ABRSM grade 8 qualification in the instrument, as well as playing the harp and violin. Beyond tutoring, his interests include social impact investing and tennis.",
        "Bocconi University BSc. Economic and Social Sciences (2025) | IB Score: 43/45 | SAT: 1580 | IGCSE 11 A*, 1 A, 1B",
        "Julian consistently scored top of his cohort in secondary school, and completed his IB and IGCSE programmes as valedictorian. He has 4 years IGCSE tutoring experience, as well as 2 years of experience mentoring students for university applications, including tutoring the SAT. He also coaches students preparing for the International Economics Olympiad, which includes teaching elements of business, economics and finance.",
        "/Images/Julian.png"
        ),

      new Tutor(
        "Carla",
        "LSE MSc student with a passion for international relations",
        "Expert",
        ["English Literature", "History", "French", "Politics"],
        "Carla is a French-British student currently pursuing a double master's degree at the London School of Economics. Having grown up in Paris, she speaks French in addition to English and German, and has aspirations of working in the European public policy sector. Outside of academics, her hobbies include painting, cooking, playing the piano and reading. She is currently learning Mandarin, and is an active member of LSE International Relations Society.",
        "LSE BSc. International Relation (First Class Honours) | LSE MSc. Political Economy of Europe",
        "Carla has been tutoring since secondary school, where she regularly volunteered as a German tutor for fellow students. She has honed her interest in education by regularly participating in a summer camp organised by her school, where she has learned to take care of children of different ages and backgrounds, teaching them art, music and drama. Carla credits this experience with developing a deep understanding of how to create a supportive learning environment, tailored to the unique interests and aspirations of her students.",
        "/Images/Carla.jpeg"
        ),

        new Tutor(
          "Matthew",
          "Lumo Co-Founder and tutor specialising in supporting IB students",
          "Subject Specialist",
          ["Economics", "Mathematics", "GMAT", "History", "Geography"],
          "Matthew was born and raised in Warsaw, Poland in a Polish-Irish family. He currently lives in Milan where he studies at Bocconi university. In his free time he enjoys travelling and photography.",
          "London Business School MSc Financial Analysis (2025) | Bocconi University Bsc. Economics and Finance (2024) | GMAT: 750 | IB Score: 43/45 | IGCSE: 9 A*, 1 A",
          "Matthew has three years of experience as an IB and IGCSE tutor, with a focus on the above-mentioned specialty subjects and IA and EE assistance. In secondary school, Matthew’s notes were highly demanded and helped his peers attain high grades on exams, demonstrating his ability to explain topics in simple, coherent terms. He achieved a perfect score on his TOK Essay and Economics IA, proving his ability to impart writing skills onto students.",
          "/Images/Matthew.png"
  
      ),

        new Tutor(
          "Maya",
          "High-achieving IB student with a strong track record of student improvement",
          "Subject Specialist",
          ["Mathematics", "Biology", "Psychology", "Economics", "English"],
          "Maya is an American who grew up in Singapore attending UWCSEA before moving to Milan to begin her Bachelor of Science at Bocconi. Her interest in the driving factors behind economic activity and passion for exploring new cultures led her to Milan. She is a native speaker in English, intermediate in Spanish, and learning Italian during her years in Italy. She loves travelling, sports, and meeting new people. Additionally, she is passionate about Diversity & Inclusion and Fintech.",
          "Bocconi University BSc. Economic and Social Sciences (2024) | IB Score: 40/45 | IGCSE: 7 A*, 2 A",
          "Maya's tutoring experience began when she was 11 years old tutoring grade 7 maths after school to her peers. At 16, she founded an organization for students in Singapore to teach English to migrant workers. She has had experience tutoring IGCSE mathematics and science and is constantly supporting her younger sister with her academic journey. In the past 2 years, she has given guidance with Maths and Biology IAs, TOK essays, and Extended Essays.",
          "/Images/Maya.png"
        ),

        new Tutor(
          "Zuzanna",
          "Highly committed bilingual Economics student with an IB score of 45 and excellent tutoring reputation.",
          "Subject Specialist",
          ["Mathematics", "Economics", "Psychology"],
          "Zuzanna is a Polish undergraduate student from Warsaw, Poland- although she spent 9 years living in Kyiv in her youth. Aside from speaking English and Polish fluently, she also speaks Russian at a C1 level, Spanish at B2 level, and is learning Italian. At Bocconi, she is president of 180 Degrees Consulting- a university-based consultancy offering services to non-profits, social enterprises, and NGOs.",
          "Bocconi University BSc. Economic and Social Sciences (2024) | IB Score: 45/45",
          "Zuzanna has four years of experience as a Mathematics tutor, helping students of all ages with preparation for final exams and general schoolwork. For the past two years, Zuzanna tutored students in IB Mathematics, both Sl and HL, preparing them for IB exams and providing IA assistance.",
          "/Images/Zuza.png"
      ),

      new Tutor(
        "Maya N.",
        "Maya is a student at Tufts University who has been tutoring since her first year of high school.",
        "Subject Specialist",
        ["English", "Global Politics"],
        "Raised in Singapore, Maya is now undertaking a Bachelor of Arts in International Relations at Tufts University. Her multidisciplinary background in international relations, Chinese, and Philosophy has sharpened her exceptional analytical abilities.",
        "Tufts University, BASc in International Relations, with minors in Philosophy and Chinese (2025) | IB Score: 42/45 (UWCSEA Singapore)",
        "Maya’s tutoring journey commenced in her first year of high school, where she began guiding children and peers from the ages of 8-18. Maya excels in mentoring English Literature students, providing guidance on crafting compelling HL essays, and navigating the intricacies of the IB Extended Essay. Her mentorship has been instrumental for individuals seeking to elevate their college application essays, leveraging her storytelling and persuasive writing techniques.",
        "/Images/Mayan.jpg"
    ),
    

      new Tutor(
        "Laura",
        "Trilingual full-time IB Business teacher and examiner",
        "Expert",
        ["Business"],
        "Laura is an open-minded professional educator who is enthusiastic about cultural diversity and international education. She has knowledge and experience in international exams (IB Business Management and IGCSE Business). She is a resilient, problem solver and creative professional, constantly looking for new challenges. She enjoys motivating students to achieve their highest potential and develop curiosity through education. She teaches in English, Spanish and Italian.",
        "Bachelor’s degree in Business Administration, Universidad de Buenos Aires | Post degree in “General Principles of Management”, Universita di Bologna in Buenos Aires",
        "Laura has been teaching business for 15 years. She is also an examiner of Paper 1 Business Management and Internal Assessments. Additionally, she is also a content editor and an expert in Extended Essay Business Management and Internal Assessments. At school she organises the workshop about entrepreneur’s development.",
        "/Images/Laura.jpg"
    ),
    new Tutor(
      "Grace",
      "A dedicated and friendly tutor with stellar IB experience",
      "Subject Specialist",
      ["Chemistry", "Geography", "Mathematics", "Economics", "English"],
      "Grace is a British-Swedish undergraduate student from London who attended both British and International schools before moving to Milan to pursue her bachelor’s degree at Bocconi University, where she was awarded the Bocconi Undergraduate International Award. She speaks fluent English and German, and is passionate about business, the fashion & luxury industry and sustainability",
      "Bocconi University BSc. International Economics and Management (2024) | IB Score: 42/45 | SAT: 1470 | ACT: 33",
      "Grace has had 2 years’ experience as an IB and IGCSE tutor helping students achieve their goals across all of her specialty subjects, including EE and IA assistance. She spearheaded the introduction of a Peer Education Programme at her high school to assist fellow students by giving them the skills and knowledge to succeed in their education related endeavours.",
      "/Images/Grace.jpg"
  ),

    new Tutor(
        "Brenda",
        "An experienced full-time teacher known for her teaching style focused on nurturing teamwork, empathy and stress management",
        "Expert",
        ["Computer Science", "Mathematics", "Spanish", "Geography"],
        "Brenda has been working in education since 2012 and is currently based in Germany. Her passion is to serve nations Her interests outside of education include walking, reading, listening to music and watching TV series.through teaching and educational projects that lead the next generations to discover and reach their full potential. She speaks English, Spanish, and German.",
        "Master in Human Rights in Politics, Law and Society, Hochshule Fulda, Germany (2021 – present) | Industrial Engineering, TecMilenio University, Mexico (2003 – 2007) | Technical Sciences Specialisation in Computer Sciences, Instituto Tecnologico y de Estudios Superiores de Monterrey (2000 – 2003)",
        "Brenda has been teaching for 11 years in various schools in both Mexico and Germany, including at university level. Additionally, she was the President of Enfoque Genesis in Mexico where she published three editions of a magazine targetted towards teenagers"
    ),

    new Tutor(
        "Alexander",
        "A highly experienced tutor with residential tutoring experience",
        "Master",
        ["Mathematics", "Physics", "Chemistry", "Biology", "Computer Science", "SAT"],
        "Alexander’s educational background revolves around science, astrophysics and education consultancy which he has pursued since 2010, however he also has a strong interest in jazz, folk and classical guitar, ancient board games, swimming, and surfing. He has spent time in many different environments and cultures such as Dubai, Monaco, Indonesia, Hong Kong, and New Mexico and speaks English fluently in addition to intermediate level French, and Mandarin (HSK 3).",
        "UCL MSc Astrophysics (First Class Honours) | UCL BSc Astrophysics (First Class Honours)",
        "Alexander has worked as a private tutor, educator, and consultant worldwide since 2010, allowing him to develop extensive knowledge of teaching students of different levels, needs, and ages. More specifically, he has created and managed intensive classes for GCSE and A-level students in Monaco and inner-city London schools, created detailed multi-year learning plans for students in Common Entrance, (i)GCSE, SAT, AP, IB, SSAT, and ISEE curricula. Additionally, he is a founding member and director of education for Phoenix Space, an educational NGO.",
        "/Images/Alex.jpeg"
    ),
    new Tutor(
      "Gusztav",
      "An LSE-educated tutor with a track record of 30+ satisfied students",
      "Master",
      ["Politics", "Economics", "Business"],
      "At LSE Gusztav was involved in many associations and committees including the Venture Capital Society and the Hungarian Society, of which he was the External Relations Officer and then President. He has worked as a  Portfolio Management Intern, whereby he sourced new start-ups and investors. His experience in business and investment combined with his passion for education allows Gusztav to truly bring business and economics studies to life in his lessons. Gusztav is currently training as an amateur boxer and is exploring his keen interest in chess.",
      "LSE Bsc. International Relations and History (Hons)",
      "Gusztav has worked closely with over 25 international high school students, assisting with their university applications. He has meticulously researched numerous Ivy League and top UK university courses allowing him to confidently  recommend suitable courses to clients and advise them throughout the application process. Among these universities are the London School of Economics, Cambridge and Oxford. Additionally, Gusztav has gained invaluable insights into education through his work as a volunteer whereby he taught Mathematics and English to Syrian refugee children in Istanbul."
    ),

    new Tutor(
      "Domonkos",
      "Chemistry MSc with a passion for interdisciplinary learning",
      "Expert",
      ["Physics", "Mathematics", "Chemistry"],
      "Domonkos combines a robust background in Chemistry with an adventurous spirit in teaching. Fluent in multiple languages and possessing eight years of diverse tutoring experience, he excels in raising students' grades and aspirations. His pedagogic approach is personalized, seeking to ignite curiosity and passion for learning in students globally. He specializes in preparing students for rigorous university challenges, particularly in the sciences, adapting to their unique learning styles.",
      "Durham University, MSc Chemistry",
      "With over 8 years of teaching experience, Domonkos has guided students through IB Chemistry, Physics, and Mathematics, consistently lifting their grades to 6 and 7. He has also mentored students preparing for A-level exams, particularly those aiming for prestigious universities like Oxford and Cambridge. His sessions are known for their cultural inclusivity, engaging students from the US, UK, and Australia, and focusing on fostering a global learning environment.",
      "/Images/Domonkos.jpg"
  ),

  new Tutor(
    "Tommaso",
    "Versatile and multilingual marine biology researcher with an extensive background in mathematics and AI",
    "Expert",
    ["Mathematics", "Economics", "Biology"],
    "With a diverse academic journey from Mathematics to Marine Biology, including a foray into Artificial Intelligence, Tommaso stands out as a polyglot and a scholar. His eight years of mentoring span a broad age range, emphasizing tailored guidance through the intricacies of IGCSE and IB, leading to successful university placements. A chef trained by Ducasse and a saxophonist, he brings creativity and discipline to his tutoring, enriching the educational experience beyond textbooks.",
    "Imperial College London, Mathematics | University of Oxford, Artificial Intelligence | IB Score: 43/45",
    "Tommaso's eight-year tutoring career covers a spectrum from one-on-one sessions to group classes, catering to students from 4 to 21 years old. His mentoring has successfully ushered students through IB exams, achieving an impressive average grade of 43/45. His hands-on approach has also facilitated internships and research projects with top-tier institutions, reflecting a commitment to real-world applications of academic learning.",
    "/Images/Tommaso.png"
),

new Tutor(
  "Giorgia",
  "Italian speaking IB graduate with extensive international school curriculum experience",
  "Subject Specialist",
  ["Mathematics", "Biology", "English"],
  "Giorgia is a Bocconi University student whose international experiences span from Monza to Dubai, culminating in a well-rounded perspective on global politics and environmental issues. Fluent in multiple languages, she has honed her skills in Arabic and French. Her active involvement in Model United Nations and a variety of conferences demonstrates her organizational skills and a deep commitment to social issues.",
  "Bocconi University, Bachelor of Science in International Politics and Government | IB Score: 39/45 | IGCSEs: Eight 9s, One 8",
  "Over two years of tutoring IGCSE students in Mathematics, Biology, and English, Giorgia has developed a personalized approach to teaching, ensuring that students not only learn but also connect with the material. She has a talent for helping non-English speakers master the language, emphasizing conversational and grammatical proficiency.",
  "/Images/Giorgia.jpg"
),

new Tutor(
  "Julieanne",
  "University of Toronto double biology major student with an IB score of 45",
  "Subject Specialist",
  ["Chemistry", "Mathematics", "Geography", "SAT", "ACT"],
  "Julieanne's academic path in cell biology and neuroscience at the University of Toronto is augmented by her diverse cultural background and her proficiency in a variety of subjects. She has a particular knack for standardized tests, evidenced by her SAT and ACT scores. Her dedication to tutoring over the past three years in subjects such as mathematics and SAT preparation is reflective of her commitment to helping students achieve their academic aspirations.",
  "University of Toronto, BSc, Double Major in Cell & Molecular Biology and Neuroscience | IB Score: 45/45 | SAT Score: 1490/1600 | ACT Score: 35/36",
  "Julieanne's three years of experience working with IB and IGCSE students have been marked by a focus on mathematics and SAT/ACT preparation. Her personal achievements in these exams have translated into effective coaching for others, leading to improved scores and successful university placements.",
  "/Images/Jul.jpg"
),

new Tutor(
  "Maja",
  "Law students with three years of tutoring experience",
  "Subject Specialist",
  ["Law", "Economics", "English"],
  "Maja was born and raised in Warsaw, Poland and has lived there all her life until her move to Madrid, Spain. She graduated from The British School of Warsaw in 2019, where she completed both the IGCSE and IB diplomas. She's pursuing a dual degree in Business Administration and Laws at IE University. Fluent in Polish and English, learning Spanish, enjoys cooking, traveling, photography, and has delved into writing for a real estate company.",
  "IE University, Dual Bachelor of Business Administration and Laws | IB Score 37/45",
  "Maja's tutoring experience involves working with first-year university students in Economics, Management, Marketing, and Law, preparing them for midterms and final exams with creative flash cards and interactive learning tools. She's also helped students meet their English requirements for university admission.",
  "/Images/Maja.jpeg"
),


new Tutor(
  "Rebekkah",
  "Lumo co-founder and tutor specialising in IB and IGCSE Support",
  "Subject Specialist",
  ["English", "Biology", "Economics"],
  "Rebekkah is Australian and grew up in Australia and Malaysia before moving to Italy. She moved to Milan to attend Bocconi University, expanding on her interest in economics. Fluent in English, learning Italian, and speaks Spanish at a B2 level. She enjoys playing the piano, reading, football, and travel.",
  "Bocconi University, BSc in International Economics and Management | IB Score: 44/45 | IGCSEs: 8 A*'s",
  "Rebekkah’s tutoring experience began by assisting two primary school students with English in Malaysia and has expanded to leading English outreach programs for various age groups in local communities.",
  "/Images/Bekky.png"
),

new Tutor(
  "Gianluca",
  "Lumo co-founder with a track record of material grade improvements",
  "Subject Specialist",
  ["Mathematics", "Economics"],
  "Gianluca is an Italian Bocconi University student from Monza who lived in Dubai for 9 years at Repton School Dubai. He speaks English, Italian, and has an advanced Spanish level. Passionate about economics, international relations, and giving back to the community, he has also taught English to children in Chiang Mai and completed the Trinity College Grade 8 Exam with Distinction.",
  "Bocconi University, Bachelor of Science in International Economics and Management",
  "With three years of experience, Gianluca has a particular expertise in tutoring IB Mathematics and Economics HL. He’s known for helping a first-year IB student improve from a 2 to a 6 in Economics HL and aiding students with their IAs and EEs in economics.",
  "/Images/Gianluca.png"
),

new Tutor(
  "Aahana",
  "Bocconi merit scholar with experience teaching biology and sciences",
  "Subject Specialist",
  ["Biology", "Mathematics", "Economics", "SAT"],
  "Aahana is an international student from Mumbai, India, studying at Bocconi University on a merit scholarship. She completed her IB Diploma with high scores in Biology, Economics, and Mathematics and has a passion for singing, which has been recognized with a Trinity School of Music award.",
  "Bocconi University, BSc in International Economics and Finance | SAT Score: 1540 |IB Score: 42/45 ",
  "Aahana has been actively involved in tutoring math and English for two years, aiding underprivileged kids and teaching arts and crafts at a local orphanage.",
  "/Images/Aahana.jpeg"
),

new Tutor(
  "Leonardo",
  "Finance student with a strong record of helping students with IAs and other assignments",
  "Subject Specialist",
  ["Mathematics", "Geography", "Economics"],
  "Leonardo is studying economics and finance at Bocconi University and comes from Genova, Italy. He lived in 5 different cities across Europe, completed his IGCSE at the British School of Warsaw and his IB diploma at the American International School of Vienna. Fascinated by high-cuisine and the culinary world, he also enjoys football and travel. He is dedicated and goal-driven, tailoring his teaching to each student's needs.",
  "Bocconi University, Bachelor of Science in International Economics and Finance",
  "Leonardo has three years of tutoring experience, initiated by his IB CAS project 'Math Block', where he conducted daily 45-minute tutoring sessions for students of all ages needing assistance with math. He has also supervised and assisted with IAs.",
  "/Images/Leonardo.jpeg"
),
  new Tutor(
    "Zaira",
    "Economics expert with extensive mentoring and policy research experience",
    "Expert",
    ["Economics", "Politics", "Spanish"],
    "Zaira was born and raised in Mexico, where she completed her Bachelor's Degree in Economics at the National Autonomous University of Mexico (UNAM). After graduating, she worked for over five years in the Mexican Ministry of Finance and for two years in one of Mexico's largest investment banks. She then moved to Europe for her Master's studies at the Hertie School in Germany.",
    "Master of Public Policy, Hertie School, Germany | Bachelor's Degree in Economics, National Autonomous University of Mexico, Mexico",
    "Zaira began mentoring during her professional years in Mexico, starting in 2015. As a departmental lead, she instructed young professionals in their initial forays as economists within the Ministry, on not just technical instruction on Economics and Financial Markets, but also on time-management and stress deadlines.",
    "/Images/zaira.jpg"
),

new Tutor(
  "Kaja",
  "Kaja is a trilingual tutor with a penchant for writing and an IB score of 44.",
  "Subject Specialist",
  ["History", "English", "Politics", "Biology"],
  "Kaja grew up in Warsaw, Poland, but at the age of 15 she moved abroad to Switzerland where she completed her International Baccalaureate diploma at the International School of Lausanne. Kaja is currently studying Economics and Politics at Bocconi University. Living and studying in an international environment has only fueled her interest in the arts, culture and politics which continue to incite her intellectual curiosity.",
  "Bocconi University, Bachelor of Science in International Economics and Politics and Government (2024) | IB Final Grade: 44/45 (7 HL History, 7 HL Global Politics, 6 HL English, 7 SL Mathematics Analysis & Approaches, 7 SL Spanish)",
  "Kaja has been mastering her writing skills through writing for university associations and Rzeczpospolita, a Polish News outlet. During her humanitarian trip to Tanzania, Kaja taught English at a local school Omolog which contributed to her strong ability to work with students from different backgrounds.",
  "/Images/Kaja.jpeg"
),

new Tutor(
  "Zsigmond",
  "MIT PhD, Cambridge MEng, and McKinsey Senior Associate with extensive teaching experience",
  "Master",
  ["Mathematics", "Business", "Chemistry"],
  "Zsigmond has a rich professional background, including his current role at McKinsey & Company and previous positions at Exxon Chemical Inc. as a Global Product Development Engineer and General Mills Inc. as a Technical Consultant for Production Engineering. He holds a Doctoral degree in Chemical Engineering from MIT, where he also served as a Graduate Student Representative and a Communication Fellow and Consultant.",
  "Massachusetts Institute of Technology, Doctoral Studies in Chemical Engineering (GPA 4.9/5.0) | University of Cambridge, BA, MEng and MA (Hons) in Chemical Engineering (Class rank: 1)",
  "Zsigmond has experience in leadership positions, one-on-one coaching, and teaching at MIT, emphasizing mentorship and detailed student feedback."
),

new Tutor(
  "Dardi",
  "Dardi specialises in tutor university-level micro/macroeconomics and mathematics",
  "Subject Specialist",
  ["Mathematics", "Economics"],
  "Dardi is an Albanian student from Tirana now residing in Milan, attending Bocconi University for a BSc in International Economics and Finance. Fluent in English, Italian, and German, he enjoys playing sports and spending time outdoors. He comes from a strong academic background with a perfect score from Turgut Ozal High School.",
  "Bocconi University, BSc in International Economics and Finance | Turgut Ozal High School, Overall Grade: 10/10 (Maths, Physics, Economics, Psychology, English)",
  "Bringing his expertise in economics and a passion for education, Dardi offers tutoring for various university-level subjects, helping students grasp complex concepts in mathematics and economics.",
  "/Images/Dardi.jpeg"
),

new Tutor(
  "Ates",
  "Ates has 2 years of professional experience as an AP and SAT tutor, as well as a U.S. universities application coach.",
  "Subject Specialist",
  ["Computer Science", "SAT", "Mathematics", "ACT"],
  "Ates is a half Turkish half American student from Istanbul, studying Economics, Management and Computer Science at Bocconi University in Milan. He has interned at PwC's Management Advisory division and at Banca Popolare di Sondrio's Credit Department, and has worked as a software developer. He’s an active member of various student associations at Bocconi and a student ambassador for the university.",
  "Bocconi University, BSc Economics, Managements and Computer Science | American Robert College of Istanbul GPA: 91.2/100 | SAT: 1490 (800 math, 690 verbal) | ACT: 33",
  "He has helped students with motivational essays and college profiles, and excels in explaining complex concepts in simpler terms. Ates is also involved in volunteer work to support Syrian refugees.",
  "/Images/Ates.jpeg"
),

new Tutor(
  "Hafsa",
  "A tutor with 6+ years of experience, pursuing a masters degree at the University of St. Gallen",
  "Subject Specialist",
  ["French", "Economics", "Mathematics"],
  "Hafsa is pursuing her Masters in Economics at the University of St. Gallen after recently completing her bachelor's degree in Economics and Finance. Originating from Pakistan and having traveled extensively, she is now enhancing her language skills by studying German in Switzerland. Passionate about economics and teaching, she has dedicated much of her time to assisting students with university entrance exams and decisions.",
  "University of St. Gallen, Masters in Economics (2025)| Bocconi University, Bachelor of Science in Economic and Social Sciences (2023)| IB Score: 43/45",
  "With over 6 years of tutoring experience, Hafsa has helped students with their SATs in Milan and has been involved in econometric research, aiming to further utilize econometrics in trade models.",
  "/Images/Hafsa.jpeg"
),

new Tutor(
  "Zayn",
  "One of Lumo's most committed tutors, Zayn first started tutoring as part of his CAS project",
  "Subject Specialist",
  ["Mathematics", "Economics", "Business","SAT"],
  "Zayn is a BSc student at Bocconi University in International Economics and Finance, originating from Jordan where he completed his IB diploma. Fluent in English and Arabic, and currently learning Italian, Zayn balances his academic pursuits with talents in piano and interests in sports.",
  "Bocconi University, Bachelor of Science in International Economics and Finance (2025) |Relevant IB Grades: 7 in Math AA HL, 7 in Economics HL, 7 in Business and Management HL",
  "In addition to tutoring for IB Math, Economics, and Business, as well as MYP and IGCSE Math, Zayn has guided students in SAT preparation, aiming to instill confidence and deep understanding in his students.",
  "/Images/Zayn.jpeg"
),

new Tutor(
  "Aiste",
  "Aiste has 4 years of tutoring experience as an IB tutor in different subjects and tutoring middle school students.",
  "Subject Specialist",
  ["Economics", "English", "Environmental Systems and Societies"],
  "Aiste is a Lithuanian student at Bocconi University, pursuing a Bachelor of Science in International Economics and Management. Fluent in English and Lithuanian, and proficient in Italian and French, she loves tennis, socializing, and has traveled extensively. Aiste is dedicated to economics and is forging a path in asset management, combining it with her knowledge of macroeconomics and finance.",
  "Bocconi University, BSC International Economics and Management (2024) | IB Final Grade: 44/45",
  "She has developed a program for underprivileged children to access tutoring, demonstrating her commitment to education equity and her passion for teaching.",
  "/Images/Aiste.jpeg"
),

new Tutor(
  "Nicolas",
  "Nicolas has been tutoring in France for 2 years at the IB level and above - BSc level in Maths - , with visible results.",
  "Expert",
  ["Mathematics", "Computer Science", "Physics"],
  "Nicolas is a French international student at the Master level in Aerospace Engineering at Politecnico di Milano. His impressive academic journey includes a Baccalaureate with a 17.1/20 mark and a Bachelor's from CentraleSupélec. His passion for Aerospace and his aim to work with either ESA or a Formula 1 team reflect his high-level sport competitiveness and love for engineering.",
  "Politecnico di Milano, MSc in Mechanics, Aeronautics and Space | CentraleSupélec, MSc in Engineering",
  "In addition to his experience in France, Nicolas has tutored at the IGCSE/IB level in both Maths and Computer Science, always focusing on the real-world applications and individual progress of his students."
),

new Tutor(
  "Nazeer",
  "Nazeer possesses over 10 years of experience developing STEM curriculum and teaching high school and university level math, physics, and chemistry.",
  "Expert",
  ["Mathematics", "Physics", "Chemistry"],
  "Nazeer is an adept educator with a bachelor’s degree in pharmacy and a master’s in biomedical engineering. He's currently advancing his expertise with a thesis on photochemical therapy. As a Senior Educational Lead at Phoenix Space, he has combined his passion for science with educational innovation.",
  "Aleppo University, BSc Pharmacy | Yildiz Technical University, MSc in Biomedical Engineering (in progress)",
  "Nazeer has contributed over a decade to tutoring in STEM subjects and to the preparation of students for standardized tests like the GRE, SAT, YOS, and Praxis, customizing his approach to each learner. Since relocating to Milan, he has tutored physics and mathematics for Lumo alongside studying.",
  "/Images/Nazeer.jpg"
),
new Tutor(
  "Jeett",
  "Jeett has a strong drive to understand concepts deeply and go beyond textbook material.",
  "Subject Specialist",
  ["Mathematics", "Economics", "Chemistry", "Biology"],
  "Jeett, originally from Coimbatore, India, is pursuing a bachelor's degree in economics and social sciences at Bocconi University, under a full merit scholarship. Known for his multifaceted interests, Jeett enjoys traveling, composing music, and engaging in a variety of educational and recreational activities.",
  "Bocconi University, BSc Economic and Social Sciences (2024) | A-Levels (2021): Mathematics A*, Economics A*, Chemistry A* | IGCSEs: 6 A*, A, B ",
  "His informal tutoring has been part of his journey as much as his formal education, emphasizing concept clarity and enjoyable learning experiences for his students.",
  "/Images/Jeett.jpg"
),
new Tutor(
  "Kit",
  "Kit is a full-time tutor with with 11+ years of experience and is currently pursuing a PhD.",
  "Master",
  ["Philosophy", "Economics", "English"],
  "Kit is an expert tutor in Philosophy and Religious Studies with a deep understanding of both ancient and modern philosophical texts, as well as major world religions. His academic path includes a bachelor's degree from the University of Warwick, a master's from University College London, and a PhD from Royal Holloway, University of London.",
  "Royal Holloway, University of London, PhD in Ancient Philosophy (forthcoming) | University College London, MA Classics | University of Warwick, BA in Philosophy (First Class Honours)",
  "In addition to his academic pursuits, Kit has extensive experience delivering lectures and seminars, providing individualized study sessions, and even coaching chess and tennis, reflecting his diverse skills and interests.",
  "/Images/Kit.jpeg"
),
new Tutor(
  "Elina",
  "A pharmaceutical sciences student with over 5 years of tutoring experience.",
  "Subject Specialist",
  ["Biology", "Chemistry", "German", "Russian"],
  "Elina is a diligent student pursuing a Bachelor of Science in Pharmacy at the University of Vienna. With an IB Diploma from the American International School of Vienna, Elina is passionate about the complexities of substances and their interactions. She works part-time in a private clinic’s management department.",
  "University of Vienna, BSc in Pharmacy (2024) | IB Score : 41/45 (2021)",
  "Since starting her tutoring journey in 2018, Elina has engaged students across a spectrum of ages and subjects. During COVID-19, she adeptly shifted to online platforms to continue her tutoring, developing tailored Russian language lessons and math tutoring programs. Her hands-on experience is further enriched by her recent tutoring in math, demonstrating her versatility and commitment to fostering educational growth in diverse learning environments."
),
new Tutor(
  "Romy",
  "A bilingual IB tutor with three years of experience and a strong academic record.",
  "Subject Specialist",
  ["History", "Economics", "French", "Mathematics"],
  "Romy is a Bocconi undergraduate with a broad international education, including an IB diploma from St Clare’s, Oxford. Fluent in French and English with intermediate Italian skills, Romy is as diverse in her linguistic abilities as she is in her academic and extracurricular endeavors. Her medical mission in Pakistan and leadership roles at Bocconi exemplify her commitment to community and personal growth.",
  "Bocconi University, BSc in International Economics and Management (2024) | IB Final Grade: 43/45",
  "Her tutoring style is informed by her own academic journey, with a focus on empowering students in subjects such as history, economics, French, and mathematics, and helping them hone their essay writing skills for EE, IAs, and TOK essays."
),
new Tutor(
  "Tom O.",
  "Tom specialises in preparing students for English and UK Entrance exams, with over 11 years of experience and many home-schooling placements with families around the world.",
  "Master",
  ["English", "11+ and 13+ Tuition", "UK Common Entrance", "History"],
  "Tom O. is a Master Residential Tutor based in London with an extensive background in home-schooling students internationally. A University of Manchester graduate with a BA (Hons) in English Literature, Tom has dedicated over a decade to helping students navigate the complexities of the UK education system.",
  "University of Manchester, BA English Literature",
  "His experience ranges from providing in-depth guidance for GCSE and A-Level courses to specialized tutoring for UK Common Entrance exams. Tom’s notable academic mentorship includes an 8-year teaching relationship with a student in London, culminating in successful placements at prestigious schools."
),
new Tutor(
  "Angelo",
  "A trilingual Bocconi student with a passion for inclusive learning and financial economics.",
  "Subject Specialist",
  ["Economics", "Mathematics", "English", "SAT"],
  "Hailing from Sicily, Angelo is currently pursuing a BSc in International Economics and Management at Bocconi University. A financial economics aficionado, he’s also a native Italian speaker, fluent in English, and conversant in several other languages. His international outlook is enriched by his travels across Europe and participation in numerous Erasmus+ projects.",
  "Bocconi University, BSc in International Economics and Management | Diploma di Liceo Classico, final grade: 100/100",
  "Through volunteering and tutoring, Angelo has dedicated himself to fostering a supportive educational environment for his peers. His tutoring began at a young age and has since expanded to assisting fellow Bocconi students in a variety of subjects, demonstrating his commitment to academic excellence and peer support."
),
new Tutor(
  "Jeremy",
  "A Bocconi student who scored 40/45 on the IB and tutors with a focus on Economics, Maths AA, and Physics.",
  "Subject Specialist",
  ["Economics", "Mathematics", "Physics"],
  "Jeremy is currently studying International Economics and Management at Bocconi University. He has cultivated a strong academic foundation through extensive international experiences, including completing his IB diploma with notable scores. In addition to his studies, Jeremy is an avid volunteer, significantly contributing to initiatives such as refurbishing computers for Ukrainian refugees.",
  "Bocconi University, International Economics and Management (2026) | IB Final Grade: 40/45 (2023) | SAT: 1430",
  "Jeremy's tutoring experience spans over 2 years, helping his peers excel in their IB journey and beyond. His approachable and analytical teaching methods have proven effective, earning him recognition for the positive impact on student performance, especially in mathematics and science.",
  "/Images/Jeremy.jpg"
),
new Tutor(
  "Vuk",
  "A dedicated tutor with standardised testing tutoring experience and a strong foundation in economics and maths.",
  "Subject Specialist",
  ["Mathematics", "Economics", "Computer Science", "Accounting", "SAT", "GMAT"],
  "Vuk, from Serbia, is deeply invested in his International Economics and Management degree at Bocconi University. With an impressive academic record and a master's degree on the horizon, Vuk is passionate about consulting, having interned at McKinsey & Co., and aims to rejoin the industry post-graduation.",
  "Bocconi University, MSc International Management (2026) | Bocconi University, BSc in International Economics and Management (2024) | Gymnazija Jovan Jovanovic Zmaj, Final GPA: 5/5 | SAT Score : 1550/1600 | GMAT Focus Edition: 715/805",
  "His experience extends to tutoring for competitive exams like the SAT and the Serbian National Math Olympics, alongside mentoring Bocconi classmates to enhance their understanding of economics and accounting.",
  "/Images/Vuk.jpeg"
),
new Tutor(
  "Jack",
  "An LSE graduate and writer with an exceptional 44/45 IB score, Jack excels in fostering academic excellence.",
  "Subject Specialist",
  ["Philosophy", "Religious Studies", "English", "Theory of Knowledge", "Extended Essay", "Journalism"],
  "Jack combines his robust academic prowess, including a First-Class Honors BSc in Philosophy from LSE and a Merit in MLitt Philosophy from St Andrews, with a talent for written expression, contributing to global discussions on complex subjects through various publications.",
  "University of St Andrews, MLitt Philosophy – Merit | London School of Economics, BSc Philosophy, Logic and Scientific Method, First Class Honors | IB Score: 44/45 (St Edward's School, Oxford)",
  "Jack has tutored extensively alongside his bachelor’s degree at the London School of Economics and master’s at the University of St Andrews. He has worked on exam preparation with sixth formers at some of the UK’s best-known schools, conducted in person lessons for younger pupils with special educational needs, and provided residential English language tutoring for a French family. His areas of expertise include Philosophy, English Literature, Journalism, IB Theory of Knowledge, Extended Essay writing and IGCSE Religious Studies.",
  "/Images/Jack.jpg"
),
new Tutor(
  "Guillermo",
  "A Cambridge-educated tutor with extensive teaching experience in Shanghai and a knack for interview preparation and essay support.",
  "Expert",
  ["Economics", "Mathematics", "Spanish", "English","11+ and 13+ Tuition", "UK Common Entrance"],
  "Guillermo brings a wealth of experience, having tutored since secondary school and volunteering at Parkside Community College. His Cambridge background in Economics and his innovative approach to teaching have earned him high praise for creating very creative lessons and being a great mentor.",
  "Trinity College, University of Cambridge, BA in Economics",
  "His students in Shanghai have benefited from his familiarity with all the content beyond their teachers at their school, and he has a lot of university experience regarding interview preparation and essay support. Guillermo has also gained significant experience in making lessons engaging and fun, both in-person and online."
),

new Tutor(
  "Kai",
  "Kai is a well-versed tutor in Economics, Physics, and Biology, having achieved top marks in these subjects.",
  "Subject Specialist",
  ["Economics", "Physics", "Biology"],
  "Kai's educational journey spans international schools in Japan and Malaysia, culminating in a BA in Economics from Keio University, Tokyo. He's fluent in both English and Japanese, emphasizing a deep understanding of subject matter over rote memorization to his students.",
  "Keio University, Tokyo, BA in Economics | IB Score: 42/45",
  "He has tutored IB students to excel in Economics and Physics, equipping them with effective study techniques. Kai also provides expert guidance for IA and extended essays, ensuring a comprehensive and enjoyable learning experience.",
  "/Images/Kai.jpg"
),

new Tutor(
  "Dareen",
  "A Politics and Government major proficient in English, French, Russian and Arabic.",
  "Subject Specialist",
  ["Global Politics", "Biology"],
  "Dareen is an international student with roots in Ukraine and Jordan, currently pursuing her major in Politics and Government at Sciences Po Paris. Fluent in Arabic, Russian, English, and proficient in French, she engages with a diverse cultural palette that enriches her academic and personal life.",
  "Sciences Po Paris, BA Politics and Government (2026) | IB Grades: 7 in Global Politics HL, A in Global Politics Extended Essay",
  "Dareen has honed her tutoring skills with family and friends, expanding her expertise to peers in subjects like Global Politics and Biology. Her methodical approach ensures students grasp intricate concepts and excel in exams like the IB and SAT."
),

new Tutor(
  "Manon",
  "A multilingual law masters student skilled in essay writing and legal studies, with an IB score of 43.",
  "Subject Specialist",
  ["Law", "History", "Biology"],
  "Manon, blending her French and New Zealand heritage, is on her way to completing a double degree in French and English Law. A globetrotter with educational and living experiences across five countries, her fluency in English, French, intermediate Spanish, and German complements her academic endeavors.",
  "University of Rennes, Master in European Law | University of Exeter, LLB English and French Law | IB Score: 43/45",
  "With a robust tutoring background from high school to university, Manon has a gift for guiding students in English and mathematics, even extending her reach to refugees. Her practical stint at law firms like Hogan Lovells and Bridgepoint honed her aspirations for corporate law.",
  "/Images/Manon.jpg"
),
  ];

  

export default tutorProfiles;
  