import { extendTheme } from '@chakra-ui/react';


const theme = extendTheme({
  styles: {
    global:
    {
      // Apply the background to the whole application
      'html, body': {       
        //bgImage: "url('/background.png')",
        //bgPosition: 'center',
        //bgRepeat: 'no-repeat',
        //bgSize: 'cover',
      }, 

      a: {
        _hover: {
          textDecoration: 'underline',
          textShadow: '1px 1px 20px white',
        },
      },
      
    },
  },
  colors: {
    mint: {
      50: '#2FBF91',
     100: '#2FBF91',
     200: '#2FBF91',
     300: '#2FBF91',
     400: '#2FBF91',
     500: '#2FBF91',
     600: '#2FBF91',
     700: '#2FBF91',
     800: '#2FBF91',
     900: '#2FBF91',
    },
      // Additional color themes
  coral: {
    50: '#FFF5F2',
    100: '#FFECE5',
    200: '#FFD9CC',
    300: '#FFC5B2',
    400: '#FFB299',
    500: '#FF9E80',
    600: '#FF8B66',
    700: '#FF774D',
    800: '#FF6433',
    900: '#FF5019'
  },
  lavender: {
    50: '#FAF4FF',
    100: '#EEDCFF',
    200: '#DDBFFF',
    300: '#CC99FF',
    400: '#BB80FF',
    500: '#AA66FF',
    600: '#9950FF',
    700: '#8833FF',
    800: '#7719FF',
    900: '#6600FF'
  },
  olive: {
    50: '#FAFAEF',
    100: '#F5F5DF',
    200: '#E0E0BF',
    300: '#CCCC99',
    400: '#B8B873',
    500: '#A3A34D',
    600: '#8F8F28',
    700: '#7A7A14',
    800: '#656500',
    900: '#515100'
  },
  denim: {
    50: '#F2F8FF',
    100: '#E5F0FF',
    200: '#CCDAFF',
    300: '#B2C4FF',
    400: '#99ADFF',
    500: '#8096FF',
    600: '#6680FF',
    700: '#4D69FF',
    800: '#3353FF',
    900: '#1A3CFF'
  },
  sky: {
    50: '#F3FAFF',
    100: '#E7F4FF',
    200: '#CCE9FF',
    300: '#B2DFFF',
    400: '#99D4FF',
    500: '#7FCAFF',
    600: '#66BFFF',
    700: '#4CB5FF',
    800: '#33A9FF',
    900: '#199EFF'
  },
  amber: {
    50: '#FFF8F3',
    100: '#FFF1E7',
    200: '#FFE2CC',
    300: '#FFD4B2',
    400: '#FFC599',
    500: '#FFB77F',
    600: '#FFA866',
    700: '#FF9A4C',
    800: '#FF8B33',
    900: '#FF7D19'
  },
  sunset: {
    50: '#FFF7F3',
    100: '#FFECE7',
    200: '#FFDACC',
    300: '#FFC9B2',
    400: '#FFB799',
    500: '#FFA47F',
    600: '#FF9266',
    700: '#FF7F4C',
    800: '#FF6D33',
    900: '#FF5B19'
  },
  rose: {
    50: '#FFF3F7',
    100: '#FFE7EF',
    200: '#FFCCE0',
    300: '#FFB2D1',
    400: '#FF99C2',
    500: '#FF7FB2',
    600: '#FF66A3',
    700: '#FF4C94',
    800: '#FF3385',
    900: '#FF1977'
  },

},
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "'Avenir Next', sans-serif",
        color: '#204f99',
        // Remove the size property if you want to use the size prop in the component itself
        // size: '3xl' 
      },
    },
    Text:{
      baseStyle: {
        fontFamily: "'Avenir Next', sans-serif",
        color: 'black'
      }
    },
  },
});

export default theme;

