
import React from 'react';
import { Box, Text, Flex, SimpleGrid, useColorModeValue, VStack, Divider, Stack, List, ListItem, ListIcon, Button, UnorderedList, Heading, Container } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import CustomTestimonial from './CustomTestimonial';
import Navbar from './Navbar';
import Footer from './Footer';
import LandingForm from './LandingForm';
import { Link as RouterLink } from 'react-router-dom';


const ServiceCard = ({ title, description, buttontext, buttondestination, emphasis }) => {
  const handleButtonClick = () => {
    window.location.pathname = `/${buttondestination}`;
  };

  
  return (
    
    <Stack
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'2xl'}
      p={6}
      rounded={'3xl'}
      align={'start'}
      spacing={4}
      border={emphasis ? '2px' : '0px'}
      borderColor={emphasis ? 'teal.400' : 'gray.100'}
      transform={emphasis ? 'scale(1.05)' : 'none'}
      _hover={{
        boxShadow: '3xl',
        transform: 'scale(1.07)',
        border: '1px',
        borderColor: 'teal.500',
      }}
      transition={'transform 0.6s, box-shadow 0.2s, border-color 0.5s'}
    >
      <Heading size={'md'} fontWeight="semibold">{title}</Heading>
      <Text color={useColorModeValue('gray.600', 'gray.200')}>
        {description}
      </Text>
      <Button onClick={handleButtonClick} colorScheme="blue">{buttontext}
      </Button>
    </Stack>
  );
};

const services = {
  "Discover Lumo": [
    { title: 'We offer in-person and online tutoring services across Europe👨‍🏫', description: "If you're looking to catch up, get ahead, or just need homework help- our tutors are here to help! We also regularly organise bootcamps ahead of IB and IGCSE exam sessions. We offer in-person tutoring in London and Milan.", emphasis: false, buttontext: "Discover Our Services", buttondestination:"OurServices"},
    { title: 'We were founded by IB graduates with an average score of 42 👨‍🎓', description: "Having survived the programme, our founders are in a unique position to empathise with IB students and offer you the individualised help and assistance that is crucial to a successful and enjoyable IBDP journey.", emphasis: false, buttontext: "Discover Our Story", buttondestination:"about" },
    { title: "Lumo's tutors have proven track records of academic success 🧠", description: "Our hand-picked team boasts students and alumni of institutions including Oxford, Cambrdidge, Harvard, and LSE. Our tutors undergo a training process to ensure they  give our students the help that they deserve.", emphasis: false, buttontext: "Meet Our Tutors", buttondestination:"tutors"  },
  ]
}

const Home = () => {
  return (
    <>
    <Navbar />
      <Container maxW="container.xl">
      <Flex flex="1" direction="column" minHeight="70vh">
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        justify="center"
        minH={{ base: 'auto', lg: '83vh' }} // Allow content to determine height on smaller screens        
      >
        <Box
          flex={{ base: 'none', lg: '1' }}
          p={{ base: '4', lg: '10' }}
          m={{ base: '4', lg: '2' }}
          textAlign={{ base: 'center', lg: 'left' }} // Center text on small screens
        >
          <Heading px={{ base: '4'}} fontSize="6xl" fontFamily="'Avenir Next', sans-serif" fontWeight="semibold" color="#204f99" mt={{ base: '4', lg: '0' }}>
            Personalised IB and IGCSE Tutoring in Milan
          </Heading>
          <List size="xl" variant="custom" spacing={5}>
      <ListItem  fontSize={25}>
        <ListIcon as={MdCheckCircle} color="#3884cc"  />
        High-quality, trained tutors
      </ListItem>
      <ListItem  fontSize={25}>
        <ListIcon as={MdCheckCircle} color="#3884cc"  />
        Thoughtful student-tutor matching
      </ListItem>
      <ListItem  fontSize={25}>
        <ListIcon as={MdCheckCircle} color="#3884cc"/>
        Consistent feedback 
      </ListItem>
      </List>
        </Box>
        <Container maxW="container.xl" py={8} my={12} flex='1'>
        <LandingForm/>
      </Container>
      </Flex>
      </Flex>
      </Container>
      <Flex>
        
      <Container maxW="container.xl" py={1} mb={150}>
      <VStack spacing={12} px={{ base: '4', lg: '75' }}>
      {Object.entries(services).map(([category, services], index) => (
        <Box key={index} width="full">
          <Heading as="h1" size="lg" textAlign="center" mb={25}>
            {category}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {services.map((service, serviceIndex) => (
              <ServiceCard key={serviceIndex} {...service} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </VStack>
      </Container>

    </Flex>
    
    <Footer/>
    </>
  );
};

export default Home;
