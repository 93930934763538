// foundersData.js
const founders = [
    {
      name: 'Louis Martin',
      role: 'Co-Founder',
      bio: 'With a decade of experience in educational consulting and private tuition, Louis leads our team with a passion for empowering learners.',
      imageSrc: `${process.env.PUBLIC_URL}/Images/Louis.png`, // Make sure to have the correct path to the images
    },
    {
        name: 'Andras Kapuvari',
        role: 'Co-Founder',
        bio: "Andras serves as an adviser to Lumo, helping with partnership development and tutor recruitment.",
        imageSrc: `${process.env.PUBLIC_URL}/Images/Andras.jpg`,
      },
    {
      name: 'Matthew Gleeson',
      role: 'Co-Founder',
      bio: "An experienced mathematics and economics tutor pursuing a degree in finance, Matthew heads Lumo's marketing and tech efforts.",
      imageSrc: `${process.env.PUBLIC_URL}/Images/Matthew.png`,
    },

    {
        name: 'Gianluca Lombardi',
        role: 'Co-Founder',
        bio: "Gianluca is an international economics student at University Bocconi with over 4 years of tutoring in physics, mathematics and economics. As a Lumo founder, Gianluca focuses on client management and business development.",
        imageSrc: `${process.env.PUBLIC_URL}/Images/Gianluca.png`,
    },
    {
        name: 'Rebekkah Howe',
        role: 'Co-Founder',
        bio: "An economics and management student and experienced tutor in English, biology, and economics, Rebekkah specializes in tutor recruitment and training, tutor-client matching, and the development of company resources.",
        imageSrc: `${process.env.PUBLIC_URL}/Images/Bekky.png`,
      },
      {
        name: 'Tom Gray',
        role: 'Co-Founder',
        bio: "Tom is a seasoned mathematics, economics, and English tutor with over 5 years of experience teaching. He also has experience in the financial services industry and focuses on Lumo’s international client relations, business development, and recruitment strategies.",
        imageSrc: `${process.env.PUBLIC_URL}/Images/Tom.png`,
      },
    // Add more founders as needed
  ];
  
  export default founders;
  