import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    useToast,
    Image,
    Avatar,
    Tag,
    TagLabel,
    HStack,
    Wrap,
    WrapItem,
    Radio,
    Stack,
    RadioGroup,
    MenuButton,
    Menu,
    MenuItem,
    MenuList,
    Modal,
    ModalCloseButton,
    ModalBody,
    Input,
    FormControl,
    FormLabel,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalHeader
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useAuth } from './AuthContext';

const TutorTable = () => {
    const [tutors, setTutors] = useState([]);
    const toast = useToast();

  const { token } = useAuth();

    useEffect(() => {
        const fetchTutors = async () => {
            try {
                const response = await fetch('https://api.lumo-edu.com/tutors');
                const data = await response.json();
                setTutors(data);
            } catch (error) {
                toast({
                    title: 'Error fetching tutors',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchTutors();
    }, []);

    const fetchTutors = async () => {
        try {
            const response = await fetch('https://api.lumo-edu.com/tutors');
            const data = await response.json();
            setTutors(data);
        } catch (error) {
            toast({
                title: 'Error fetching tutors',
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDelete = async (tutorId) => {
        // Call the API to delete the tutor
        try {
          const response = await fetch('https://api.lumo-edu.com/tutors/deleteTutor', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ tutorId }),
          });
    
          if (response.ok) {
            // Remove the tutor from state to update the UI
            setTutors(tutors.filter((tutor) => tutor.id !== tutorId));
            fetchTutors(); // Refresh the list of tutors
            toast({
              title: 'Tutor deleted',
              description: "The tutor has been successfully deleted.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          } else {
            // Handle any errors returned from the backend
            throw new Error('Failed to delete the tutor');
          }
        } catch (error) {
          toast({
            title: 'An error occurred',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
    }

    const getColorScheme = (subject) => {
        // Simple hash function for color assignment
        const colorSchemes = [
        'teal', 'mint', 'facebook', 'linkedin', 'whatsapp', 'twitter', 'telegram', 'purple', 'orange', 'coral', 'green', 'blue', 'lavender', 'cyan', 'pink', 'yellow',  'sky', 'denim', 'rose', 'gray', 'sunset'
        ];;
        return colorSchemes[subject.length % colorSchemes.length];
    };
    //for the modal overlay
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentTutor, setCurrentTutor] = useState(null);

    const handleEditClick = (tutor) => {
        setCurrentTutor(tutor);
        setEditModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setEditModalOpen(false);
      };

      const handleSaveChanges = async (updatedTutor) => {
        try {
          const response = await fetch('https://api.lumo-edu.com/tutors/editTutor', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedTutor),
          });
      
          const data = await response.json(); // Assuming the server responds with JSON
      
          if (response.ok) {
            handleCloseModal();
            fetchTutors(); // Refresh the list of tutors
            // Optionally, update the local state to reflect the changes
            toast({
              title: 'Success',
              description: 'Tutor profile updated successfully.',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          } else {
            // If the server response is not ok, handle errors
            toast({
              title: 'Failed to update tutor',
              description: data.message || 'An error occurred. Please try again.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          // If there is an error during the fetch
          toast({
            title: 'Network error',
            description: 'Unable to connect to the server. Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          console.error('Error updating tutor:', error);
        }
      };
      
        


    return (
        <Box maxW="6xl" mx="auto" px={8} py={4} my={25} borderWidth="0px" borderRadius="3xl" boxShadow="2xl" background="white" >
            <Table size = "sm" variant="simple">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>Name</Th>
                        <Th>Tutor Level</Th>
                        <Th>Subjects</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tutors.map(tutor => (
                        <Tr key={tutor.id}>
                            <Td>
                                {tutor.ImagePath && tutor.ImagePath !== 'undefined' ? (
                                    <Image src={tutor.ImagePath} alt={`Photo of ${tutor.Name}`} boxSize="50px" borderRadius="full" objectFit='cover' />
                                ) : (
                                    <Avatar name={tutor.Name} />
                                )}
                            </Td>
                            <Td>
                                <Menu>
                                <MenuButton as={Button} variant='link' colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                {tutor.Name}
                                </MenuButton>
                                <MenuList>
                                <MenuItem icon={< EditIcon/>} onClick={() => handleEditClick(tutor)} style={{ fontSize: '1.1em', padding: '10px 20px' }}>Edit Tutor Profile</MenuItem>
                                <MenuItem icon={< DeleteIcon/>} onClick={() => handleDelete(tutor.tutorId)} style={{ fontSize: '1.1em', padding: '10px 20px' }}>Delete</MenuItem>
                                </MenuList>
                                </Menu>
                            </Td>
                            <Td>{tutor.Level}</Td>
                            <Td>
                            <Wrap>
                                    {tutor.Subjects.map((subject, index) => (
                                        <Tag size="md" variant="solid" colorScheme={getColorScheme(subject)}>
                                        {subject}
                                        </Tag> 
                                    ))}
                                </Wrap>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
<Modal isOpen={isEditModalOpen} onClose={handleCloseModal} size="xl">
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Edit Tutor Profile</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {currentTutor && Object.keys(currentTutor).map((key) => {
        if (key !== 'tutorId'&& key!== 'Subjects') { // Exclude the ID from editable fields
          return (
            <FormControl my={3} key={key} isRequired>
              <FormLabel>{key}</FormLabel>
              <Input
                defaultValue={currentTutor[key]}
                onChange={(e) => setCurrentTutor({ ...currentTutor, [key]: e.target.value })}
              />
            </FormControl>
          );
        }
        return null;
      })}
            <FormControl my={3} key={"Subjects"} isRequired>
              <FormLabel>Subjects</FormLabel>
              <Input onChange={(e) => { if (currentTutor) { setCurrentTutor({ ...currentTutor, ["Subjects"]: e.target.value.split(',').map(s => s.trim())
        });
      }
    }}
    defaultValue={currentTutor ? currentTutor["Subjects"].join(', ') : ''}
  />
            </FormControl>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={() => handleSaveChanges(currentTutor)}>
        Save
      </Button>
      <Button variant="ghost" onClick={handleCloseModal}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>
            </Table>
        </Box>
        
    );
};
export default TutorTable;


