import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  Container,
  Button,
  useColorModeValue,
  VStack,
  Divider
} from '@chakra-ui/react';
import Navbar from './Navbar';
import Footer from './Footer';
import LandingForm from './LandingForm';

const services = {
    'School-level Tutoring': [
      { title: 'IB and IGCSE Tutoring 🌍', description: "If you're looking to catch up, get ahead, or just need homework help- our tutors are here to help.", emphasis: true },
      { title: 'Assignment Help 📚', description: "We'll help you with writing your extended essay, from finding an idea to making final corrections.", emphasis: false },
      { title: 'Exam Preparation ⏰', description: 'Looking for a final push ahead of the big day? Get help from our tutors who have achieved top grades themselves.', emphasis: false },
    ],
    'Specialist Services': [
      { title: 'Standardised Tests ✅', description: 'We offer students preparing for the SAT, GMAT, ACT, and IELTS guidance on exam strategy and common question types.', emphasis: true },
      { title: 'Profile Building 📈', description: "Make your university application stand out from the crowd by engaging in research projects and volunteering initiatives.", emphasis: false },
      { title: 'University Applications 🎓', description: 'Get guidance on applying to university, from shortlisting schools to writing essays and making final admissions decisions.', emphasis: false },
    ],
    // Add more categories and services as needed
  };

const ServiceCard = ({ title, description, emphasis }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={6}
      rounded={'3xl'}
      align={'start'}
      spacing={4}
      border={emphasis ? '2px' : '0px'}
      borderColor={emphasis ? 'teal.400' : 'gray.100'}
      transform={emphasis ? 'scale(1.05)' : 'none'}
      _hover={{
        boxShadow: '3xl',
        transform: 'scale(1.07)',
        border: '1px',
        borderColor: 'teal.500',
      }}
      transition={'transform 0.6s, box-shadow 0.2s, border-color 0.5s'}
    >
      <Heading size={'md'} fontWeight="semibold">{title}</Heading>
      <Text color={useColorModeValue('gray.600', 'gray.200')}>
        {description}
      </Text>
    </Stack>
  );
};


const OurServices = () => {
  return (
    <>
    <Navbar/>
    <Container maxW="container.xl" py={8} mb={12}>
    <Heading as="h1" size="xl" textAlign="center" mb={0} m={8} py={0} fontFamily="'Avenir Next', sans-serif" fontWeight="bold" color="#204f99">
          Discover Our Services
        </Heading>
        <Container maxW="container.lg" py={2}>
        <Text textAlign="center" mb={6}>
          Thanks to our extensive tutor book with wide-ranging experiences, Lumo is able to offer tutoring services for students preparing for most subjects across most curricula, as well as those preparing for standardised tests such as the SAT, IELTS, or GMAT.
        </Text>
        </Container>
    <VStack spacing={12} px={{ base: '4', lg: '75' }}>
      {Object.entries(services).map(([category, services], index) => (
        <Box key={index} width="full">
          <Heading as="h2" size="md" textAlign="left" mb={6}>
            {category}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {services.map((service, serviceIndex) => (
              <ServiceCard key={serviceIndex} {...service} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </VStack>

    <Container maxW="container.lg" mt={20} mb={50}>
    <Heading as="h1" size="xl" textAlign="center" mb={0} m={8} py={0} fontFamily="'Avenir Next', sans-serif" fontWeight="bold" color="#204f99">
          Want to find out more?
        </Heading>
        <Container maxW="container.lg" py={2}>
        <Text textAlign="center" mb={6}>
          Use the form below to book your first lesson or to get in touch with our team. Whether your a student, parent, or represent a school, our team will do its best to get in touch with you as soon as possible.
        </Text>
        </Container>
      <LandingForm/>
    </Container>
    </Container>
    <Footer/>
    </>
  );
};

export default OurServices;

