// React imports
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'; // Make sure to install axios with `npm install axios`

// Chakra UI imports
import {
  Box, Container, Heading, SimpleGrid, Text, VStack,
  Menu, MenuButton, MenuList, MenuItemOption, MenuOptionGroup, Button, Stack, Tag, Wrap, WrapItem, Avatar, Image,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useColorModeValue, MenuItem, MenuDivider
} from '@chakra-ui/react';

// Component imports
import Navbar from './Navbar';
import Footer from './Footer';
import { ChevronDownIcon } from '@chakra-ui/icons';

// Other declarations
const colorSchemes = [
  'teal', 'mint', 'facebook', 'linkedin', 'whatsapp', 'twitter', 'telegram',
  'purple', 'orange', 'coral', 'green', 'blue', 'lavender', 'cyan', 'pink', 'yellow', 'orange', 'sky', 'denim', 'rose', 'gray', 'sunset'
];
const allLevels = ["Subject Specialist", "Expert", "Master"];

const Tutors = () => {
  const [tutorProfiles, setTutorProfiles] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([...allLevels]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTutor, setSelectedTutor] = useState(null);

  // Fetch tutor profiles from the API when the component mounts
  useEffect(() => {
    axios.get('https://api.lumo-edu.com/tutors')
      .then(response => {
        setTutorProfiles(response.data);
        const subjects = response.data.flatMap(tutor => tutor.Subjects);
        const uniqueSubjects = Array.from(new Set(subjects));
        setSelectedSubjects(uniqueSubjects);
      })
      .catch(error => {
        console.error('Error fetching tutors:', error);
      });
  }, []);

  const allSubjects = useMemo(() => {
    const subjects = new Set(tutorProfiles.flatMap(tutor => tutor.Subjects));
    return Array.from(subjects);
  }, [tutorProfiles]);

  const [selectedSubjects, setSelectedSubjects] = useState(allSubjects);

  const getColorScheme = (subject) => {
    const index = allSubjects.indexOf(subject);
    return colorSchemes[index % colorSchemes.length];
  };

  const handleCardClick = (tutor) => {
    setSelectedTutor(tutor);
    onOpen();
  };

  const filteredTutors = useMemo(() => tutorProfiles.filter((tutor) => {
    const levelMatch = selectedLevels.includes(tutor.Level);
    const subjectMatch = tutor.Subjects.some(subject => selectedSubjects.includes(subject));
    return levelMatch && subjectMatch;
  }), [tutorProfiles, selectedLevels, selectedSubjects]);



return (
    <>
      <Navbar />
      
      <Container maxW="container.xl" py={8} >
        <Heading as="h1" size="xl" textAlign="center" mb={0} m={8} py={0} fontFamily="'Avenir Next', sans-serif" fontWeight="bold" color="#204f99">
          Meet Our Tutors
        </Heading>
        <Container maxW="container.lg" py={2}>
        <Text textAlign="center" mb={6}>
          Lumo offers a wide range of tutors with a wide range of subjecte expertise and varying levels of experience. We carefully vet all of our tutors to ensure they meet our rigorous standards, and offer all tutors training opportunities. Please use the buttons below to filter by tutor level and subject, and click on a profile to learn more about the tutor.
        </Text>
        </Container>
        
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4} justify="center" align="center" mb={6}>
        <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="blue">
          Tutor Level
        </MenuButton>
        <MenuList minWidth="240px">
        <MenuItem onClick={() => setSelectedLevels(allLevels)}>Select All</MenuItem>
        <MenuItem onClick={() => setSelectedLevels([])}>Deselect All</MenuItem>
        <MenuDivider />
          <MenuOptionGroup value={selectedLevels} type="checkbox" onChange={(value) => setSelectedLevels(value)}>
            {allLevels.map((level) => (
              <MenuItemOption key={level} value={level}>
                {level}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
        </Menu>

        <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="blue">
        Subjects Taught
        </MenuButton>
        <MenuList minWidth="240px">
        <MenuItem onClick={() => setSelectedSubjects(allSubjects)}>Select All</MenuItem>
        <MenuItem onClick={() => setSelectedSubjects([])}>Deselect All</MenuItem>
        <MenuDivider />
        <MenuOptionGroup value={selectedSubjects} type="checkbox" onChange={(value) => setSelectedSubjects(value)}>
          {allSubjects.map((subject) => (
            <MenuItemOption key={subject} value={subject}>
              {subject}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        </MenuList>
        </Menu>
        </Stack>



        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mx={{ base: 4, md: 75 }}>
          {filteredTutors.map((tutor, index) => (
          <Button
          key={index}
          onClick={() => handleCardClick(tutor)}
          variant="ghost"
          h="auto"
          p={0} // Removes padding to make the button fit tightly around the VStack
          w="full" // Ensure the button takes the full width of the grid column
          alignItems="stretch" // Stretch button to fill the height
          transition="transform 0.2s"
          _hover={{
            img: {
              transform: 'scale(1.15)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            },
            Box:{
              transform: 'scale(1.15)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)'
            }
          }}

          >
            <VStack
              key={index}
              borderWidth="0px"
              borderRadius="lg"
              overflow="hidden"
              shadow={'xl'}
              p={5}
              spacing={4}
              style={{ whiteSpace: 'normal' }}
              allign = "start"
              w = "full"
              rounded="3xl"
              background={'white'}
              _hover={{
                boxShadow: '2xl',
                transform: 'scale(1.04)',
                borderColor: 'teal.500',
                borderWidth: '1px'
              }}
              transition={'transform 0.4s, box-shadow 0.2s, border-color 0.2s'}
            >
            {
            tutor.ImagePath && tutor.ImagePath !== 'undefined' ? (
            <Image
              borderRadius="full"
              boxSize="75px"
              src={tutor.ImagePath}
              alt={`Photo of ${tutor.Name}`}
              transition={'transform 0.4s, box-shadow 0.4s'}
              objectFit='cover'
            />
            ) : (
            <Avatar
              size="lg"
              width="65px"
              height="65px"
              name={tutor.Name}
              transition={'transform 0.4s, box-shadow 0.4s'}
            />
            )
            }
              <Heading size="md" my={2} fontWeight="bold" fontFamily="'Avenir Next', sans-serif">{tutor.Name}</Heading>
              <Tag colorScheme="blue">{tutor.Level}</Tag>
              <Text fontSize="md" fontWeight="normal" fontFamily="'Avenir Next', sans-serif">
                {tutor.ShortBio}
              </Text>
              <Wrap>
                {tutor.Subjects.map((subject, subjectIndex) => (
                  <WrapItem key={subjectIndex}>
                    <Tag size="md" variant="solid" colorScheme={getColorScheme(subject)}>
                      {subject}
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
              
            </VStack>
          </Button>       
          ))}
        </SimpleGrid>
        {selectedTutor && (
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{selectedTutor.Name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontWeight="bold">Education:</Text>
                <Text mb={4}>{selectedTutor.Education}</Text>
                <Text fontWeight="bold">Bio:</Text>
                <Text mb={4}>{selectedTutor.Bio}</Text>
                <Text fontWeight="bold">Experience:</Text>
                <Text mb={4}>{selectedTutor.Experience}</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        
      </Container>
      <Footer/>
    </>
  );
};

export default Tutors;
