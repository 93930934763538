import React from 'react';
import { Box, Container, Text, Stack, Link } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="gray.100" color="gray.600">
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Stack direction={'column'}>
        <Text>© {new Date().getFullYear()} Lumo Privee Srl | All rights reserved.</Text>
        <Text>Sede legale: Via San Senatore 8, 20122 Milano</Text>
        <Text>C.F. e P.IVA: 12864230961</Text>
        </Stack>
        <Stack direction={'row'} spacing={6}>
          <Link href={'/'}>Home</Link>
          <Link href={'/about'}>About</Link>
          <Link href={'/Tutors'}>Tutors</Link>
          <Link href={'/privacy'}>Privacy Policy</Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
