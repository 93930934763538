import React from 'react';
import {
  Box,
  Heading,
  Text,
  Container,
  SimpleGrid,
  VStack,
  Image,
  Divider,
  useColorModeValue,
  Stack
} from '@chakra-ui/react';
import Navbar from './Navbar';
import founders from './Founders';
import ProfileCard from './ProfileCard';
import Footer from './Footer';



const Privacy = () => {
  return (
    <>
    <Navbar/>
    <Container maxW="container.lg" py={12}>
      <Heading as="h1" size="xl" textAlign="center" py={3} mb={6}>Privacy Policy</Heading>
      <Text textAlign="left" mb={6} fontFamily="'Avenir Next', sans-serif">
      1. INTRODUCTION

LUMO PRIVEE SRL with registered office at Via San Senatore 8, 20122 Milano(Partita IVA 12864230961) (“us”, or “we”), is Data Controller in respect of your personal data. This Privacy Notice provides detailed information on the collection, processing and storage of your personal data operated by us. If you have any questions about this Privacy Notice, you can contact us via rebekkah@lumo-edu.com.

Please read the following carefully to understand our practices regarding your personal data and how it will be treated.
2. DEFINITIONS

-	"Users": any person to whom we provide consultancy work to pursuant to the Contract.
-	"personal data": any information concerning a person that makes that person identifiable.
-	"Contract": the contract under which we provide consultancy work in favour of Users.


3. WHAT PERSONAL DATA WE COLLECT ABOUT YOU

We acquire, process and store the personal data of potential, current and previous Users and their respective parents as well as anyone exercising parental responsibility over the Users and, in any case, of anyone who consents to the processing of their personal data by us for the purposes set out in paragraph 8. Personal data which is submitted to us may include:
-	names, surnames, places of birth, nationalities, codice fiscale, addresses, email addresses, telephone numbers;
-	schools attended, school assessments, documentation relating to education, language certifications, exams taken, school subjects
-	any payment methods and other financial and/or credit information.
Other types of data acquired, processed and stored by us may include special category data such as:


If you contact us with a complaint or query, we may keep a record of any phone number used to call us as well as the correspondence and the period of time it took for us to deal with a query or any request you had. We may also record your levels of satisfaction with the services we have provided.

4. METHODS OF PERSONAL DATA COLLECTION

We collect your personal data in various ways, including:
-	when you contact us;
-	by electronic and paper documentation, including contact emails, registration forms, contracts entered into with us, contact forms completed on our website or on other sites that collaborate with us, digital apps, social media, emails, paper letters, consent forms, via our website, invoices or accounting documents;
-	through third parties, such as contact persons, schools or professionals; and
-	by means of publicly available resources.


5. LEGAL BASIS FOR PROCESSING

We will only process your personal data where we have a legal basis to do so. The legal basis will depend on the purposes for which we have collected and used your personal data. In almost every case the legal basis will be one of the following: - Consent: For example, where you have provided your consent to receive the services or other information (including marketing) from us. You can withdraw your consent at any time.
- Our legitimate business interests: Where it is necessary for us to understand our customers, promote our services and operate effectively, provided in each case that this is done in a legitimate way which does not unduly affect your privacy and other rights.
- Performance of a contract with you (or in order to take steps prior to entering into a contract with you): For example, where you have decided to engage our services and we need to use your contact details and payment information in order to provide the services to you.
- Compliance with law: Where we are subject to a legal obligation and need to use your personal data in order to comply with that obligation.
6. METHODS OF DATA PROCESSING

We process your personal data in accordance with the provisions of the GDPR. The processing will be carried out using automated, manual, paper-based methods and will take place using suitable tools to guarantee adequate security and confidentiality through internal procedures and security software that avoid the risk of loss, unauthorized access and accidental dissemination of the same.
7. DATA RETENTION

Your personal data may be kept by us for ten (10) years from the last activity carried out or in any case for all the time necessary to carry out the purposes referred to at paragraph 8 below. After this period, we will delete your personal data or may keep the data in anonymous form and for statistics.

Your personal information is securely stored with Amazon Web Services (AWS)
8. PURPOSE OF PROCESSING

We may process your personal data mainly for the purpose of carrying out consultancy work and all the other obligations referred to in the Contract.

In particular, personal data will be processed by us in order to:
-	Contact you personally, by telephone, email, social media and any other means of communication as part of our services to you.
-	Inform parents or anyone exercising parental responsibility over Users about the progress of the lessons put in place by us.
-	Organize the tutoring services with the Users.
-	Provide a safe and secure working environment.
-	Propose commercial offers, send newsletters, invite to events, presentations, open days, meetings.
-	Manage invoices, fees and accounts.


9. COOKIES AND TRACKING

We do not use cookies or tracking technologies on our website.
10. COMMUNICATION OF PERSONAL DATA TO THIRD PARTIES

We do not share your personal information with third parties. Your data is used solely for the purposes outlined in this Privacy Policy.
11. YOUR RIGHTS

Under the GDPR, you have certain rights in relation to your personal data. These include rights to:
-	access your personal data;
-	request your personal data to be rectified, integrated and updated;
-	request that your personal data be deleted;
-	request data portability;
-	request to object or limit the processing of your personal data; and
-	lodge a complaint to the relevant data protection authority. We will handle any request to exercise your rights in accordance with applicable law and any relevant legal exemptions. If you wish to exercise any of these rights please contact us at rebekkah@lumo-edu.com. As above, you have the right to complain to a data protection authority if you think we have processed your personal information in a manner which is unlawful or breaches your rights. If you have such concerns we request that you initially contact us (at rebekkah@lumo-edu.com) so that we can investigate, and hopefully resolve, your concerns.

If you are a parent or guardian, you have the right to request information relating to you or the relevant User however we may require the consent of the Users themselves in the event that they are of an age for which it is possible to understand the implications of such a request. We will handle any such request in accordance with applicable law and any relevant legal exemptions.

We cannot disclose confidential information that is related to the purpose of providing education, examinations or providing examination papers to external entities or individuals. We are not authorized to disclose the confidential information of staff or tutors.
12. UPDATING THIS PRIVACY NOTICE AND CONTACTING US We may update this Privacy Notice from time to time. Any substantial change that may affect you and the processing of your personal data will be notified on the website or through a specific newsletter. This Privacy Notice must be read in conjunction with our other policies and any contractual terms and conditions. Questions and comments regarding this Privacy Notice should be sent to: rebekkah@lumo-edu.com
      </Text>
    </Container>
    <Footer/>
    </>
  );
};

export default Privacy;