import React, { useState, useRef } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
  Container,
  Heading,
  Text,
  useToast,
  useColorModeValue,
  VStack,
  Stack,
  SimpleGrid,
  Link,
  Checkbox
} from '@chakra-ui/react';
import Navbar from './Navbar';
import {EmailIcon} from '@chakra-ui/icons'
import { FiUpload } from 'react-icons/fi';
import Footer from './Footer';

const benefits = {
  '': [
    { title: 'Flexibility 💪', description: "We have no minimum weekly commitment requirement, and allow tutors freedom with scheduling lessons.", emphasis: false },
    { title: 'Competitive Rates 💸', description: "Lumo is proud to offer rates above industry standards, and fairly compensate tutors depending on their experience.", emphasis: false },
    { title: 'Personal Development 👩‍🏫', description: "We offer novice tutors training, opportunities to enhance their teaching skillsets, and social outings.", emphasis: false }
  ],
};

const BenefitCard = ({ title, description, emphasis }) => {
return (
  <Stack
    bg={useColorModeValue('white', 'gray.700')}
    boxShadow={'lg'}
    p={6}
    rounded={'3xl'}
    align={'start'}
    spacing={4}
    border={emphasis ? '2px' : '0px'}
    borderColor={emphasis ? 'teal.400' : 'gray.100'}
    transform={emphasis ? 'scale(1.05)' : 'none'}
    _hover={{
      boxShadow: '2xl',
      transform: 'scale(1.07)',
      borderColor: 'teal.500',
    }}
    transition={'transform 0.6s, box-shadow 0.2s, border-color 0.2s'}
  >
    <Heading size={'md'} fontWeight="semibold">{title}</Heading>
    <Text color={useColorModeValue('gray.600', 'gray.200')}>
      {description}
    </Text>
  </Stack>
);
};

const TutorApplicationForm = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInfo, setFileInfo] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    bio: '',
    subjects: ''
  });

  const [uploadConfirmation, setUploadConfirmation] = useState('')
  
  const toast = useToast();

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleFileClick = () => {
    fileInputRef.current.click();
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5242880) { // 5MB in bytes
      setSelectedFile(file);
      setUploadConfirmation(`Attached file: ${file.name}, ${(file.size / 1024).toFixed(2)} KB`);
    } else {
      toast({
        title: 'Error',
        description: 'File size should be less than 5MB',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setFileInfo('');
      setSelectedFile(null);
    }
  };
  
  async function handleSubmit(event) {
    event.preventDefault();
  
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('email', formData.email);
    formDataObj.append('bio', formData.bio);
    formDataObj.append('subjects', formData.subjects);
    if (selectedFile) {
      formDataObj.append('file', selectedFile, selectedFile.name);
    }
  
    try {
      const response = await fetch('https://api.lumo-edu.com/submit-form', {
        method: 'POST',
        body: formDataObj,
      });
  
      const message = await response.text();
  
      if (response.ok) {
        // Handle success case here
        toast({
          title: 'Application Submitted',
          description: 'Your application has been successfully submitted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Reset the form state if necessary
        setFormData({
          name: '',
          email: '',
          bio: '',
          subjects: ''
        });
        setSelectedFile(null);
        setUploadConfirmation(null);
      } else {
        // Handle server-side failure here
        toast({
          title: 'Submission Failed',
          description: `There was a problem with your submission: ${message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      // Handle client-side failure here
      toast({
        title: 'Application Submitted',
        description: 'Your application has been successfully submitted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Reset the form state if necessary
      setFormData({
        name: '',
        email: '',
        bio: '',
        subjects: ''
      });
      setSelectedFile(null);
      setUploadConfirmation(null);
    }
  }
  
  


  return (
    <>
    <Navbar/>
    <Container py={8} maxW="container.lg">
    <Heading as="h1" size="xl" textAlign="center" mb={0} m={8} py={0}  fontFamily="'Avenir Next', sans-serif" fontWeight="bold" color="#204f99">
          Become a Tutor
        </Heading>
        <Container maxW="container.md">
        <Text textAlign="center" mb={2}>
          At Lumo, we believe that our team of talented tutors is foremost to achieving our goal of educational empowerment. That's why we look to generously award our tutors for the work they do for us, and to offer growth opportunities for them wherever possible.
        </Text>
        </Container>
        </Container>
      <Container maxW="container.xl" py={3} px={75}>
      <VStack spacing={10}>
      {Object.entries(benefits).map(([category, services], index) => (
        <Box key={index} width="full">
          <Heading as="h1" size="lg" textAlign="center" mb={6}>
            {category}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {services.map((service, serviceIndex) => (
              <BenefitCard key={serviceIndex} {...service} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </VStack>
    </Container>
    <Container maxW="container.md" py={75}>
      <Heading as="h1" size="xl" textAlign="center" py={0} mb={6}>Join our Team</Heading>
      <Text textAlign="center" mb={6} fontFamily="'Avenir Next', sans-serif">
        We're always looking for talented individuals with a passion for teaching to join our team and are always happy to hear from potential tutors, regardless of their experience level.
      </Text>
      <Box maxW="3xl" mx="auto" px={8} py={4} borderWidth="0px" borderRadius="3xl" boxShadow="2xl" bg={useColorModeValue('white', 'gray.700')}>
        <form onSubmit={handleSubmit}>
        <FormControl id="name" isRequired>
            <FormLabel my= {3}>Name</FormLabel>
            <Input
              name="name"
              type="text"
              placeholder="Your Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel my= {3}>Email address</FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="youremail@example.com"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </FormControl>
          <FormControl id="education" isRequired>
            <FormLabel my= {3}>Education</FormLabel>
            <Textarea
              name="education"
              placeholder="BA University of Oxford 2021, A-Levels (Maths A*, Computer Science A*, English A)"
              value={formData.bio}
              onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
              minHeight="100px" 
            />
          </FormControl>
          <FormControl id="subjects">
            <FormLabel my= {3}>Subjects you can teach</FormLabel>
            <Input
              name="subjects"
              type="text"
              placeholder="Math, Science, English..."
              value={formData.subjects}
              onChange={(e) => setFormData({ ...formData, subjects: e.target.value })}
            />
          </FormControl>
          <FormControl id="file" isRequired>
        <FormLabel htmlFor='file'my= {3}  id="CV" isRequired>Please upload your CV</FormLabel>
        <Input
          type='file'
          id='file'
          name='file'
          ref={fileInputRef}
          onChange={handleFileChange}
          hidden // Hide the file input
        />
        {uploadConfirmation && <Text>{uploadConfirmation}</Text>}
        <Button
          leftIcon={<FiUpload />} // Using react-icons for the upload icon
          onClick={handleFileClick}
          variant='outline'
        >
          Upload CV/Resume
        </Button>
      </FormControl>
      <Checkbox name="agreement" onChange={(e) => setIsAgreed(e.target.checked)} my={5} colorScheme="blue">
    I consent to having my data processed according to the <Link color='#204f99' href={'/privacy'} isUnderlined>Privacy Policy</Link>
  </Checkbox>
          <Button  colorScheme="blue" rightIcon={<EmailIcon />} type="submit" isDisabled={!isAgreed}>Submit Application</Button>
        </form>
      </Box>
    </Container>
    <Footer/>
    </>
  );
};

export default TutorApplicationForm;
