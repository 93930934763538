import React from 'react';
import { Box, Image, Text, VStack, Heading } from '@chakra-ui/react';

const ProfileCard = ({ name, role, bio, imageSrc }) => {
  return (
    <VStack
      bg={'white'}
      borderWidth={'0px'}
      boxShadow={'lg'}
      p={6}
      rounded={'3xl'}
      align={'start'}
      spacing={4}
      _hover={{
        boxShadow: '2xl',
        transform: 'scale(1.04)',
        borderColor: 'teal.500',
      }}
      
      transition={'transform 0.4s, box-shadow 0.2s, border-color 0.2s'}
      w={{ base: "100%", md: "100%" }} // Control the width of the card
      
        
    >
    <Box
        width="150px" // Set a fixed width
        height="150px" // Set a fixed height
        overflow="hidden" // Ensures the image does not bleed outside the container
        borderRadius="full" // Makes the container circular
        mx="auto" // Centers the container
        mb={4} // Margin bottom for spacing
        
    >
        <Image borderRadius={'full'} boxSize={''} src={imageSrc} alt={name} />
      </Box>

      <Heading size='s'>{name}</Heading>
      <Text>{bio}</Text>
    </VStack>
  );
};

export default ProfileCard;


