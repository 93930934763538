import React, { useState,useRef } from 'react';
import {
  Box, FormControl, FormLabel, Input, Textarea, Button, useToast, Heading,
  RadioGroup, Radio, Stack, Text
} from '@chakra-ui/react';
import axios from 'axios';
import Footer from './Footer';
import Navbar from './Navbar';
import { FiUpload } from 'react-icons/fi';
import TutorTable from './tutorTable';
import { useAuth } from './AuthContext';

function AddTutorForm() {
    
  const [formData, setFormData] = useState({
    Name: '',
    ShortBio: '',
    Education: '',
    Bio: '',
    Experience: '',
    Level: '',
    Subjects: '',
    Image: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fileInputRef = useRef(null);
  const [uploadConfirmation, setUploadConfirmation] = useState('');

  const { token } = useAuth();

  const handleFileClick = () => {
    // When the button is clicked, trigger the file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, Image: e.target.files[0] });
    const file = e.target.files[0];
    if (file) {
        // Optionally, you can set the file name as the upload confirmation
        setUploadConfirmation(`File selected: ${file.name}`);
      }
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    let imagePath = '';

    if (formData.Image) {
      try {
        const uploadConfig = await axios.post('https://api.lumo-edu.com/tutors/uploadImage', {}, {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }});
        await axios.put(uploadConfig.data.presignedUrl, formData.Image, {
          headers: {
            'Content-Type': formData.Image.type,
          },
        });
        imagePath = uploadConfig.data.imageUrl;
      } catch (error) {
        toast({
          title: 'Image Upload Failed',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setIsSubmitting(false);
        return;
      }
    }

    try {
      await axios.post('https://api.lumo-edu.com/tutors/addTutor', {
        Name: formData.Name,
        ShortBio: formData.ShortBio,
        Level: formData.Level,
        Bio: formData.Bio,
        Subjects: formData.Subjects.split(','),
        Education: formData.Education,
        Experience: formData.Experience,
        ...(imagePath && { ImagePath: imagePath }),
      },
      {
        headers: {
            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        }
    }
      );
      toast({
        title: 'Tutor Added',
        description: 'The tutor was successfully added!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // Reset form data
      setFormData({
        Name: '',
        ShortBio: '',
        Education: '',
        Bio: '',
        Experience: '',
        Level: '',
        Subjects: '',
        Image: null
      });

    } catch (error) {
      toast({
        title: 'Failed to Add Tutor',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
    <Box maxW="3xl" mx="auto" px={8} py={4} my={25} borderWidth="0px" borderRadius="3xl" boxShadow="2xl" background="white" >
    <Heading as="h1" size="lg" textAlign="center">Add a Tutor</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired my={3}>
          <FormLabel>First Name</FormLabel>
          <Input placeholder="Bekky or Bekky H." maxW={300} name="Name" value={formData.Name} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Level</FormLabel>
          <RadioGroup name="Level" onChange={value => setFormData({ ...formData, Level: value })}>
            <Stack direction="row">
              <Radio value="Subject Specialist">Subject Specialist</Radio>
              <Radio value="Expert">Expert</Radio>
              <Radio value="Master">Master</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Subjects</FormLabel>
          <Input name="Subjects" value={formData.Subjects} placeholder= "Mathematics, Economics, German, French, Arabic" onChange={handleChange} />
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Short Bio</FormLabel>
          <Input name="ShortBio" value={formData.ShortBio} onChange={handleChange} placeholder="An LSE graduate with an IB score of 45 specialised in assisting students with essay writing."/>
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Education</FormLabel>
          <Textarea minHeight="75px"  name="Education" type="text" placeholder="London Business School Master in Financial Analysis (2025) | Bocconi University BSc. Economic and Social Sciences (2024) | IB Score : 45/45 " value={formData.Education} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Experience</FormLabel>
          <Textarea minHeight="150px"  name="Experience" type="text" placeholder="Maya’s tutoring journey commenced in her first year of high school, where she began guiding children and peers from the ages of 8-18. Maya excels in mentoring English Literature students, providing guidance on crafting compelling HL essays, and navigating the intricacies of the IB Extended Essay. Her mentorship has been instrumental for individuals seeking to elevate their college application essays, leveraging her storytelling and persuasive writing techniques." value={formData.Experience} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired my={3}>
          <FormLabel>Long Bio</FormLabel>
          <Textarea minHeight="150px"  name="Bio" type="text" placeholder="Gianluca is an Italian Bocconi University student from Monza who lived in Dubai for 9 years at Repton School Dubai. He speaks English, Italian, and has an advanced Spanish level. Passionate about economics, international relations, and giving back to the community, he has also taught English to children in Chiang Mai and completed the Trinity College Grade 8 Exam with Distinction." value={formData.Bio} onChange={handleChange} />
        </FormControl>

        <FormControl id="file">
      <FormLabel htmlFor='file' my={3}>Profile Image</FormLabel>
      <Input
        type='file'
        id='file'
        name='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        hidden // Hide the file input
      />
      {uploadConfirmation && <Text>{uploadConfirmation}</Text>}
      <Button
        leftIcon={<FiUpload />} // Using react-icons for the upload icon
        onClick={handleFileClick}
        variant='outline'
      >
        Upload Profile Image
      </Button>
    </FormControl>

        <Button
          mt={4}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
        >
          Add Tutor
        </Button>
      </form>
    </Box>
    </>
  );
}

export default AddTutorForm;
