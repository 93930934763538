import React, { useState,useRef } from 'react';
import AddTutorForm from './AddTutorForm';
import TutorTable from './tutorTable';
import Footer from './Footer';
import Navbar from './Navbar';
import {Flex,Box} from '@chakra-ui/react';
import LoginModal from './LoginModal';
import { useAuth } from './AuthContext';

const Dashboard = () => {
    const { isAuthenticated } = useAuth();
  
    if (!isAuthenticated) {
        return (
          <>
            <Navbar />
            <Flex direction="column" align="center" justify="center" minHeight="100vh">
              <Box height="500px" width="100%" bg="gray.200" opacity="0.4">
              </Box>
              <LoginModal />
            </Flex>
          </>
        );
      }
  
    return (
      <>
        <Navbar />
        <Box><AddTutorForm /></Box>
        <Box><TutorTable /></Box>
        <Footer />
      </>
    );
  };

export default Dashboard;
